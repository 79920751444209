.contactsPage a {
    text-decoration: none;
    color: #5D5D5D;
}

.contentPadding {
    padding: 1em;
}

.contacts {
    display: grid;
    grid-template-columns: auto 1fr;
    margin: 1em 0;
}
.contacts > *{
    padding: .3em 1em .3em 0;
}