.feedbackBlock {
    background: #E2E2EA;
    width: 1176px;
    margin: 64px auto 64px;
    padding: 128px;
    border-radius: 24px;

    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}


.feedbackBlock h1 {
    line-height: 3em;
}

.feedbackBlock p {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
    color: #92929D;
}