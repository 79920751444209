header {
    background: #458FF6;
    display: flex;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: stretch;

    margin: auto;
    flex: 0 1 1176px;
    min-height: 4rem;
}

.header, .header * {
    text-decoration: none;
}

.logoPlaceOuter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;

    vertical-align: middle;
    text-align: center;
}

.logoPlaceInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;

    color: white;
    font-weight: bold;
    font-size: 1.5em;
    vertical-align: middle;
    text-align: center;
}

.logoImage {
    max-height: 1em;
    margin: 0.5rem;
}

.topMenu {
    display: flex;
    flex-direction: row;
    gap: 2em;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.topMenu .menuItem {
    color: white;
}


.menuItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;

    text-decoration: none;
}

.iconMenu {
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}