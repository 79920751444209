.blackOverlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 999;
    z-index: 22000;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100wv;
    height: 100%;
    background: rgba(0, 0, 0, .7);
}

.verticalAligner {
    margin: 0;
    margin-bottom: auto;
    margin-top: auto;
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.whiteWindow {
    background: white;
    border-radius: 16px;
    margin: 4px;
    max-width: 100%;
    margin: auto;
    box-shadow: 0 5px 10px -5px rgba(157, 157, 149, .4), 0 10px 20px -5px rgba(157, 157, 149, .5);
}

.modalContent {
    position: relative;
    width: 640px;
    padding: 32px;
}