.shopTop {
    max-width: 1176px;
    margin: 0 auto 0;
    padding-top: 3rem;
}

.col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.shopTop h2 {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgb(222, 226, 230);
    font-size: min(2rem, calc(1.325rem + 0.9vw));
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.shopTop h5 {
    font-size: 1.25rem;
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
}

.cardRow {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.carousel {
    position: relative;
    width: 100%;

    /* being image thumbnail */
    background-color: #fff;
    border: 1px solid white;
    border-radius: 0.375rem;
    max-width: 100%;
    height: auto;
}

.imageCarousel {
    flex: 1 1 250px;
}

.titleAndButtons {
    flex: 3 1 500px;
    padding-top: 0.25rem;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
}

.carouselInner {
    aspect-ratio: 1/1;
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 1rem;
/* 
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center; */

    /* object-fit: fill; */
}

.cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carouselControlPrevIcon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    position: relative;
}

.carouselControlNextIcon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carouselControlPrevIcon,
.carouselControlNextIcon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

.visuallyHidden,
.visuallyHiddenFocusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.carouselControlNext,
.carouselControlPrev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}

.carouselControlPrev {
    left: 0;
}


.carouselControlNext {
    right: 0;
}

.btnGroup {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 0.25em;
}

.buyLink {
    flex-shrink: 1;
}

.reviewLink {
    flex-shrink: 2;
}

.btnGroup > * {
    display: flex;
    align-items: center;
}