* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
    align-content: stretch;
    display: grid;
    font-family: Arial, Verdana, sans-serif;
    grid-template-rows: auto 1fr auto;
    justify-content: stretch;
    min-height: 100vh;
    width: 100%;
}

header {
    width: 100%;
    background: #458FF6;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: stretch;

    margin: auto;
    width: 1176px;
    min-height: 90px;
}

.header, .header * {
    text-decoration: none;
}

.logo-place-outer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;

    vertical-align: middle;
    text-align: center;
}

.logo-place-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;

    color: white;
    font-weight: bold;
    font-size: 1.5em;
    vertical-align: middle;
    text-align: center;
}

.top-menu {
    display: flex;
    flex-direction: row;
    gap: 2em;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.top-menu .menuitem {
    color: white;
}

.menuitem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;

    text-decoration: none;
}

.icon-menu {
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.content {
}

.fix-width {
    width: 1175px;
    margin: 0 auto;
}

.scan-photo-block-bg {
    width: 100%;
    background: #F6F7F8;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
    align-items: center;
}

.scan-photo-block {
    margin: 5em;
    width: 1176px;

    display: flex;
    gap: 128px;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.scan-photo-block-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    gap: 4em;
    width: 22em;
}

h1 {
    font-weight: 700;
    font-size: 3em;
}

.scan-photo-block h1 {
    line-height: 120%;
}

.scan-photo-block-left p {
    color: #92929D;
    font-weight: 400;
    font-size: 1em;
    line-height: 136%;
}

.scan-photo-block-left .order-buttons {
    display: flex;
    gap: 16px;
}

.scan-photo-block-left .order-buttons > * {
    display: inline-block;
    white-space: nowrap;
}

.scan-photo-block-right > img {
    max-width: 500px;
    max-height: 500px;
    border-radius: 2rem;
    border: 1px solid grey;
}

.button {
    border-radius: 0.75em;
    display: inline-block;
    font-weight: 700;
    line-height: 120%;
    padding: 1.125em 1.5em;
    text-decoration: none;
    text-align: center;
}

.button-primary {
    background: #FC724D;
    border: 0;
    color: white;
}

.button-secondary {
    background: transparent;
    border: 1px solid #FC724D;
    color: #FC724D;
}

.dvr-block {
    background: #E2E2EA;
    width: 1176px;
    margin: 64px auto 64px;
    padding: 128px;
    border-radius: 24px;

    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.dvr-block * {
    text-align: center;
}

.dvr-block h1 {
    line-height: 3em;
}

.dvr-block p {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
    color: #92929D;
}

footer {
    background: #F6F7F8;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.footer-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    gap: 3em;

    width: 1176px;
    padding: 32px 0;
}

.footer-menu .menuitem {
    color: #5B5B5B;
}

.footer-down {
    border-top: 1px solid #9D9D9D;
    width: 1176px;
    padding: 16px 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
}

.copyright {
    color: #92929D;
}

.contact-small {
    color: #92929D;
}

.contact-small a {
    text-decoration: none;
    color: #9D9D9D;
}

.create-order-content {
    padding-top: 3em;
    padding-bottom: 3em;
}

.btn-group {
    background: #F5F5F5;
    border-radius: 0.875em;
    color: #6A6A6A;
    padding: 2px;


    display: inline-flex;
    flex-direction: row;
    gap: 0;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    vertical-align: middle;
}

.btn-group > input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
    margin: 0;
}

.btn-group > .button {
    background: transparent;
}

input, .btn-group > input + * {
    font-size: 0.875em;
    font-weight: 600;
}

.btn-group > input[type=radio]:checked + .button,
.btn-group > input[type=radio]:active + .button {
    background: white;
    color: #458FF6;
}

h3 {
    font-weight: 400;
    font-size: 1.125em;
    line-height: 3em;
    margin-top: 1em;
}

h4 {
    font-weight: 300;
    font-size: 0.875em;
    line-height: 1em;
    margin-top: 1em;
    margin-bottom: .5em;
}

select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
}

select {
    padding: 1em 3em 1em 1em;
    border-radius: 1em;
}

select {
    /* styling and reset */
    border: 1px solid #EAEAEA;
    border-radius: .5em;
    color: #458FF6;
    font-weight: 600;
    font-size: 0.875em;
    line-height: 1.5em;

    /* reset */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=text], input[type=tel] {
    border-radius: .5em;
    border: 1px solid #EAEAEA;
    height: 3.5em;
    width: 25em;
    padding: 1em;
}

input[type=submit] {
    margin: 1em 0;
    width: 25em;
}