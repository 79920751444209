footer {
    background: #F6F7F8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: space-between;
}

.footerMenu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    gap: 3em;

    max-width: 1176px;
    padding: 32px 0;
}

.menuItem {
    text-decoration: none;
}

.footerMenu .menuItem {
    color: #5B5B5B;
}

.footerDown {
    border-top: 1px solid #9D9D9D;
    max-width: 1176px;
    width: 100%;
    padding: 16px 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-content: stretch;
    align-items: stretch; */
}

.copyRight {
    color: #92929D;
}

.contactSmall {
    color: #92929D;
}

.contactSmall a {
    text-decoration: none;
    color: #9D9D9D;
}